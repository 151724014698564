import React, { useState, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import {
  Card,
  Space,
  Button,
  Tag,
  Modal,
  Form,
  Divider,
  Input,
  Spin,
} from "antd";
import { ToastContainer, toast } from "react-toastify";
import Table from "../../Component/Table/Table";
import { psApiCalling } from "../../Component/API/Index";
import { LoadingOutlined } from "@ant-design/icons";
export default function LeadList() {
  const [data, setData] = useState([]);
  const [update, setUpdate] = useState(false);
  const [updateForm] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [iid, setIid] = useState(null);
  const [col, setCol] = useState([
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   render: (_, record) => <></>,
    // },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          <Button
            type="primary"
            onClick={() => {
              setUpdate(true);
              setIid(_.id);
              updateForm.setFieldsValue({
                status: _.status,
              });
            }}
          >
            Edit
          </Button>{" "}
          {_.current_status === "1" ? (
            <Button
              danger
              onClick={() => {
                deleteIndustry(_.id, "0");
              }}
            >
              Make Inactive
            </Button>
          ) : (
            <Button
              type={"primary"}
              onClick={() => {
                deleteIndustry(_.id, "1");
              }}
            >
              Make Active
            </Button>
          )}
        </>
      ),
    },
  ]);

  const deleteIndustry = (id, type) => {
    let params = { action: "DELETE_JESTA_LEAD_STATUS", id: id, type: type };
    psApiCalling(params).then((res) => {
      if (res.status === "success") {
        toast.success(res.message);
        getLeadList();
      } else {
        toast.error(res.message);
      }
    });
  };

  const getLeadList = () => {
    let params = { action: "GET_JESTA_LEAD_LIST" };
    psApiCalling(params).then((res) => {
      if (Array.isArray(res)) {
        setData(res);
      }
    });
  };

  const onFinishUpdate = (values) => {
    let params = {
      action: "UPDATE_JESTA_LEAD_STATUS",
      iid: iid,
      name: values.status,
    };
    psApiCalling(params).then((res) => {
      if (res.status === "success") {
        toast.success(res.message);
        getLeadList();
        setUpdate(false);
      } else {
        toast.error(res.message);
      }
    });
  };

  useEffect(() => {
    getLeadList();
  }, []);

  return (
    <>
      <Box style={{ marginTop: "8px" }}>
        <Grid
          md={12}
          style={{
            marginTop: "0px",
            msOverflowStyle: "none",
            scrollbarWidth: "0px",
          }}
        >
          <Card
            title="Lead Status"
            extra={
              <Button
                href="/createJestaLeadStatus"
                style={{ background: "#5f27cd", color: "#FFFFFF" }}
              >
                Create Lead Status
              </Button>
            }
            style={{
              marginTop: "30px",
              msOverflowStyle: "none",
            }}
          >
            <Table data={data} col={col} />
          </Card>
        </Grid>
      </Box>
      <ToastContainer />
      <Modal
        title="Update Lead Status"
        centered
        open={update}
        width={600}
        footer={null}
        onCancel={() => setUpdate(false)}
      >
        <Divider />
        <div>
          <Form
            form={updateForm}
            layout="vertical"
            style={{
              marginBottom: 0,
            }}
            onFinish={onFinishUpdate}
          >
            <Form.Item
              label="Lead Status"
              name="status"
              rules={[
                {
                  required: true,
                  message: "Lead Status is required",
                },
              ]}
              style={{
                display: "inline-block",
                width: "calc(99.8% - 8px)",
              }}
            >
              <Input placeholder="Status" />
            </Form.Item>
            <Form.Item label=" " colon={false}>
              {isLoading ? (
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 24,
                      }}
                      spin
                    />
                  }
                />
              ) : (
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%", background: "#3E4095" }}
                >
                  Update
                </Button>
              )}
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
}
