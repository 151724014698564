import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Select,
  Space,
  Tooltip,
  Typography,
  DatePicker,
} from "antd";
import { Card, Tag } from "antd";
import { ToastContainer, toast } from "react-toastify";
import { Grid, Box } from "@mui/material";
import moment from "moment";
import dayjs from "dayjs";
import { psApiCalling } from "../API/Index";
const { Option } = Select;

export default function CreateLeadForm(props) {
  console.log(props);
  const { data } = props;
  const [form] = Form.useForm();
  const [managerList, setManagerList] = useState([[]]);
  const [designation, setDesignation] = useState([]);
  const [users, setUsers] = useState([]);
  const [reportingUser, setReportingUser] = useState(props.reporting);
  const [allUsers, setAllUsers] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [reporting, setReporting] = useState(null);
  const [value, setValue] = useState("");
  const [adhar, setAdhar] = useState("");
  const [filteredReporting, setFilteredReporting] = useState([]);
  const handleChange = (e) => {
    const { value } = e.target;
    // Filter out non-numeric characters
    const numericValue = value.replace(/\D/g, "");
    setValue(numericValue);
    form.setFieldsValue({
      phone_number: numericValue,
    });
  };
  const handleChangeAdhar = (e) => {
    const { value } = e.target;
    // Filter out non-numeric characters
    const numericValue = value.replace(/\D/g, "");
    setAdhar(numericValue);
    form.setFieldsValue({
      aadhar: numericValue,
    });
  };
  const onFinish = (values) => {
    const givenDate = moment(values.employee_dob.$d, "DD-MM-YYYY");
    const today = moment();
    const eighteenYearsAgo = moment().subtract(18, "years");
    const isOlderThanEighteenYears = givenDate.isBefore(eighteenYearsAgo);
    if (isOlderThanEighteenYears) {
      console.log("v======>", values);
      let report;
      if (typeof values.reporting === "object") {
        report = values.reporting.value;
      } else {
        report = values.reporting;
      }
      let params = {
        action: "UPDATE_EMPLOYEE_JESTA",
        employee_name: values.employee_name,
        employee_dob: moment(values.employee_dob.$d).format("DD-MM-YYYY"),
        employee_gender: values.employee_gender,
        employee_email: values.employee_email,
        employee_type: values.employee_type,
        aadhar: values.aadhar,
        pan_card: values.pan_card,
        bank_name: values.bank_name,
        ifsc: values.ifsc,
        account_number: values.account_number,
        employee_address: values.employee_address,
        phone_number: values.phone_number,
        employee_role: values.employee_role,
        employee_password: values.employee_password,
        employee_designation: values.employee_designation,
        id: props.data.id,
      };
      psApiCalling(params).then((res) => {
        if (res.status === "success") {
          toast.success(res.message);
        } else {
          toast.error(res.message);
        }
      });
    } else {
      toast.error("Employee date should not be less then 18 year");
    }
  };

  const handleChangeBank = (e) => {
    const { value } = e.target;
    // Filter out non-numeric characters
    const numericValue = value.replace(/\D/g, "");
    setValue(numericValue);
    form.setFieldsValue({
      account_number: numericValue,
    });
  };

  const onChangeDate = (values) => {
    let dob = moment(values.employee_dob).format("DD-MM-YYYY");
  };
  const getManager = () => {
    let params = { action: "GET_MANAGER_LIST" };
    psApiCalling(params).then((res) => {
      if (Array.isArray(res)) {
        setManagerList(
          res.map((item) => {
            return {
              label: item.data.full_name,
              value: item.data.id,
            };
          })
        );
      }
    });
  };

  const getDesignation = () => {
    let params = {
      action: "GET_DESIGNATION_LIST_REPORTING",
    };
    psApiCalling(params).then((res) => {
      if (Array.isArray(res)) {
        // setData(res);
        setDesignation(res);
      }
    });
  };

  const getAllUsers = () => {
    let params = { action: "GET_USERS_FOR_REPORTING" };
    psApiCalling(params).then((res) => {
      console.log("res=====>", res);
      if (Array.isArray(res)) {
        setAllUsers(res);
        setUsers(
          res.map((item) => {
            return {
              label: item.full_name,
              value: item.id,
            };
          })
        );
      }
    });
  };

  const getDesignationParent = (id) => {
    let params = { action: "GET_DESIGNATION_PARENT", id: id };
    psApiCalling(params).then((res) => {
      console.log("parent1========>", res);
      if (res.status === "success") {
        changeDesignation(res.parent);
      }
    });
  };

  useEffect(() => {
    //changeDesignation()
    getDesignationParent(data.employee_designation);
    console.log(
      "data.reporting_to",
      data.reporting_to,
      "data.employee_designation",
      data.employee_designation
    );
    form.setFieldsValue({
      employee_name: data.full_name,

      employee_dob: dayjs(data.user_dob, "DD-MM-YYYY"),
      employee_gender: data.user_gender,
      employee_email: data.user_email,
      employee_role: data.employe_role,
      employee_password: data.password,
      phone_number: data.phone_number,
      employee_designation: data.employee_designation,
      reporting: data.reporting_to,
      employee_type: data.user_type,
      aadhar: data.aadhar,
      pan_card: data.pan_card,
      bank_name: data.bank_name,
      ifsc: data.ifsc,
      account_number: data.account_number,
      employee_address: data.user_address,
    });
  }, [props.data, reporting]);

  const getReportingUser = (id) => {
    let arr = allUsers.filter((item) => item.value === id);

    let newArr = arr.map((item) => {
      return {
        label: item.label,
        value: item.id,
      };
    });

    // console.log("new Arr======>", newArr);
    // setReportingUser(newArr);
    return newArr;
  };

  useEffect(() => {
    // filterReporting();
  }, [props.data]);

  useEffect(() => {
    getManager();
  }, []);

  useEffect(() => {
    getDesignation();
    getReportingUserList(data.employee_designation);
  }, [data]);

  useEffect(() => {
    getAllUsers();
  }, [data]);

  const getReportingUserList = (id) => {
    let params = { action: "GET_REPORTING_USER_LIST", id: id };
    psApiCalling(params).then((res) => {});
  };

  const changeDesignation = (id) => {
    console.log("called");
    let arr = allUsers.filter((item) => item.employee_designation === id);

    setReportingUser(
      arr.map((item) => {
        return {
          label: item.full_name,
          value: item.id,
        };
      })
    );
    console.log("filter====>", arr);
  };

  const getMyReporting = (id) => {
    console.log(id);
  };

  return (
    <>
      <Form
        form={form}
        name="complex-form"
        layout="vertical"
        onFinish={onFinish}
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
      >
        <Form.Item
          style={{
            marginBottom: 0,
          }}
        >
          <Form.Item
            label="Employee Name"
            name="employee_name"
            rules={[
              {
                required: true,
                message: "Employee name is required",
              },
            ]}
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
            }}
          >
            <Input placeholder="Employee Name" />
          </Form.Item>
          <Form.Item
            label="Employee Dob"
            name="employee_dob"
            rules={[
              {
                required: true,
                message: "Employee dob is required",
              },
            ]}
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              margin: "0 8px",
            }}
          >
            <DatePicker
              format={"DD-MM-YYYY"}
              onChange={onChangeDate}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Form.Item>
        <Form.Item
          style={{
            marginBottom: 0,
          }}
        >
          <Form.Item
            label="Employee Gender"
            name="employee_gender"
            rules={[
              {
                required: true,
                message: "Employee gender is required",
              },
            ]}
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
            }}
          >
            <Select
              defaultValue="Select Gender"
              style={{
                width: "100%",
              }}
              onChange={() => {}}
              options={[
                {
                  label: "Male",
                  value: "Male",
                },
                {
                  label: "Female",
                  value: "Female",
                },
                {
                  label: "Other",
                  value: "Other",
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Employee Email"
            name="employee_email"
            rules={[
              {
                type: "email",
                message: "Email address is not valid",
              },
              {
                required: true,
                message: "Email address is required",
              },
            ]}
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              margin: "0 8px",
            }}
          >
            <Input
              type={"email"}
              placeholder="Email Address"
              inputMode={"email"}
              onInput={(e) => (e.target.value = e.target.value.toLowerCase())}
            />
          </Form.Item>
        </Form.Item>
        <Form.Item
          style={{
            marginBottom: 0,
          }}
        >
          <Form.Item
            label="Employee Role"
            name="employee_role"
            rules={[
              {
                required: true,
                message: "Employee role is required",
              },
            ]}
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
            }}
          >
            <Select
              defaultValue="Select Role"
              style={{
                width: "100%",
              }}
              onChange={() => {}}
              options={[
                {
                  label: "In-House",
                  value: "in_house",
                },
                {
                  label: "Service Man",
                  value: "Service Man",
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Employee Password"
            name="employee_password"
            rules={[
              {
                required: true,
                message: "Employee password is required",
              },
            ]}
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              margin: "0 8px",
            }}
          >
            <Input.Password placeholder="Email Password" />
          </Form.Item>
        </Form.Item>
        <Form.Item
          style={{
            marginBottom: 0,
          }}
        >
          <Form.Item
            label="Employee Phone Number"
            name="phone_number"
            rules={[
              {
                required: true,
                message: "Employee Phone is required",
              },
            ]}
            style={{
              display: "inline-block",
              width: "calc(100%)",
            }}
          >
            <Input
              placeholder="Phone Number"
              type="text"
              value={value}
              onChange={handleChange}
              maxLength={10}
              minLength={10}
            />
          </Form.Item>
          {/* <Form.Item
            label="Select Designation"
            name="employee_designation"
            rules={[
              {
                required: true,
                message: "Designation is required",
              },
            ]}
            style={{
              display: "inline-block",
              width: "calc(24% - 8px)",
              margin: "0 8px",
            }}
          >
            <Select
              defaultValue="Select Designation"
              style={{
                width: "100%",
              }}
              onChange={(v, i) => {
                changeDesignation(i.parent);
                console.log(i.parent);
              }}
              options={designation}
            />
          </Form.Item>
          <Form.Item
            label="Reporting To"
            name="reporting"
            rules={[
              {
                required: true,
                message: "Reporting is required",
              },
            ]}
            style={{
              display: "inline-block",
              width: "calc(24% - 8px)",
              margin: "0 8px",
            }}
          >
            <Select
              defaultValue="Select User"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{
                width: "100%",
              }}
              onChange={(v) => {
                console.log("v======>", v);
              }}
              options={reportingUser}
            />
          </Form.Item> */}
        </Form.Item>

        <Form.Item
          style={{
            marginBottom: 0,
          }}
        >
          <Form.Item
            label="Employee Type"
            name="employee_type"
            rules={[
              {
                required: true,
                message: "Please select employee type",
              },
            ]}
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
            }}
          >
            <Select
              defaultValue="Select Employee Type"
              style={{
                width: "100%",
              }}
              onChange={() => {}}
              options={[
                {
                  label: "Fulltime",
                  value: "Fulltime",
                },
                {
                  label: "Intern",
                  value: "Intern",
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Employee AADHAR"
            name="aadhar"
            rules={[
              {
                required: true,
                message: "Employee aadhar is required",
              },
            ]}
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              margin: "0 8px",
            }}
          >
            <Input
              placeholder="AADHAR Number"
              type="text"
              value={adhar}
              onChange={handleChangeAdhar}
              maxLength={12}
              minLength={12}
            />
          </Form.Item>
        </Form.Item>

        <Form.Item
          style={{
            marginBottom: 0,
          }}
        >
          <Form.Item
            label="Employee PAN"
            name="pan_card"
            rules={[
              {
                required: true,
                message: "Employee pan is required",
              },
            ]}
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
            }}
          >
            <Input placeholder="PAN Number" maxLength={10} />
          </Form.Item>
          <Form.Item
            label="Bank Name"
            name="bank_name"
            rules={[
              {
                required: true,
                message: "Bank name is required",
              },
            ]}
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              marginLeft: "10px",
            }}
          >
            <Input placeholder="Bank Name" />
          </Form.Item>
        </Form.Item>

        <Form.Item
          style={{
            marginBottom: 0,
          }}
        >
          <Form.Item
            label="Bank IFSC"
            name="ifsc"
            rules={[
              {
                required: true,
                message: "Bank ifsc is required",
              },
            ]}
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
            }}
          >
            <Input placeholder="Bank IFSC Code" />
          </Form.Item>
          <Form.Item
            label="Employee Bank Account Number"
            name="account_number"
            rules={[
              {
                required: true,
                message: "Bank account number is required",
              },
            ]}
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              margin: "0 8px",
            }}
          >
            <Input
              placeholder="Bank Account Number"
              type="text"
              value={value}
              onChange={handleChangeBank}
            />
          </Form.Item>
        </Form.Item>

        <Form.Item
          style={{
            marginBottom: 0,
          }}
        >
          <Form.Item
            label="Employee Address"
            name="employee_address"
            rules={[
              {
                required: true,
                message: "Employee Address is required",
              },
            ]}
            style={{
              display: "inline-block",
              width: "calc(100% - 8px)",
            }}
          >
            <Input placeholder="Employee Address" />
          </Form.Item>
        </Form.Item>

        <Form.Item label=" " colon={false}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "100%", background: "#3E4095" }}
          >
            Update User
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
