import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../../Component/Common/Header/Header";
import "./Dashboard.css";
import Sidebar from "../../Component/Common/Sidebar/Sidebar";
import DashboardCounter from "../../Component/DashboardCounter/DashboardCounter";
import JestaLeads from "../../Component/JestaLeads";
export default function Dashboard() {
  const [type, setType] = useState("");

  const getSession = () => {
    let uid = localStorage.getItem("psAdminSessionID");
    let uname = localStorage.getItem("psAdminSessionName");
    let type = localStorage.getItem("psAdminSessionType");

    if (uid === null) {
      window.location.href = "/";
    } else {
      setType(type);
    }
  };
  useEffect(() => {
    getSession();
  });
  if (type === "Jesta" || type === "Jesta User") {
    return (
      <Container fluid className={"mainContainer"}>
        <Row>
          <Col md={2} style={{ padding: "0px", margin: "0px" }}>
            <Sidebar to={window.location.pathname} />
          </Col>
          <Col md={10}>
            <Header />
            <main>
              <Container fluid>
                <JestaLeads />
              </Container>
            </main>
          </Col>
        </Row>
      </Container>
    );
  } else if (type === "Jesta User") {
    return (
      <Container fluid className={"mainContainer"}>
        <Row>
          <Col md={2} style={{ padding: "0px", margin: "0px" }}>
            <Sidebar to={window.location.pathname} />
          </Col>
          <Col md={10}>
            <Header />
            <main>
              <Container fluid>
                <JestaLeads />
              </Container>
            </main>
          </Col>
        </Row>
      </Container>
    );
  } else {
    return (
      <Container fluid className={"mainContainer"}>
        <Row>
          <Col md={2} style={{ padding: "0px", margin: "0px" }}>
            <Sidebar to={window.location.pathname} />
          </Col>
          <Col md={10}>
            <Header />
            <main>
              <DashboardCounter />
            </main>
          </Col>
        </Row>
      </Container>
    );
  }
}
