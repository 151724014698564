import React, { useState, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import { Card, Space, Button, Tag } from "antd";
import { ToastContainer, toast } from "react-toastify";
import Table from "../../Component/Table/Table";
import { psApiCalling } from "../../Component/API/Index";
import { Image } from "react-bootstrap";
import moment from "moment";
export default function WebsiteRequestList() {
  const [data, setData] = useState([]);
  let col;
  const [type, setType] = useState(window.location.pathname.split("/")[2]);
  if (type === "request") {
    col = [
      {
        title: "Fullname",
        key: "name",
        render: (item) => <p>{item.name}</p>,
      },

      {
        title: "Service",
        key: "service",
        render: (item) => <Tag color="#27ae60">{item.setvice}</Tag>,
      },

      {
        title: "Email",
        key: "email",
        render: (item) => <Tag color="#3498db">{item.email}</Tag>,
      },
      {
        title: "Phone",
        key: "phone",
        render: (item) => <Tag color="#1abc9c">{item.phone}</Tag>,
      },
      {
        title: "Submitted Time",
        key: "time",
        render: (item) => (
          <Tag color="#8e44ad">
            {moment(item.time).format("DD-MM-YYYY") +
              ", " +
              moment(item.time).format("HH:mm a")}
          </Tag>
        ),
      },
    ];
  } else {
    col = [
      {
        title: "Fullname",
        key: "name",
        render: (item) => <p>{item.name}</p>,
      },

      {
        title: "Email",
        key: "email",
        render: (item) => <Tag color="#3498db">{item.email}</Tag>,
      },
      {
        title: "Phone",
        key: "phone",
        render: (item) => <Tag color="#1abc9c">{item.phone}</Tag>,
      },
      {
        title: "Submitted Time",
        key: "time",
        render: (item) => (
          <Tag color="#8e44ad">
            {moment(item.time).format("DD-MM-YYYY") +
              ", " +
              moment(item.time).format("HH:mm a")}
          </Tag>
        ),
      },
    ];
  }

  const deleteService = (id) => {
    let params = { action: "DELETE_WEBSITE_SERVICE", id: id };
    psApiCalling(params).then((res) => {
      if (res.status === "success") {
        getServiceList();
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getServiceList = () => {
    if (type === "request") {
      let params = { action: "GET_WEBSITE_REQUEST" };
      psApiCalling(params).then((res) => {
        if (Array.isArray(res)) {
          setData(res);
        }
      });
    }
  };

  const getReuest = () => {
    let val;
    if (type === "lead_reuest") {
      val = "LEAD_REQUEST";
    } else {
      val = "PRICING_REQUEST";
    }
    let params = { action: "GET_REQUEST", type: val };
    psApiCalling(params).then((res) => {
      if (Array.isArray(res)) {
        setData(res);
      }
    });
  };

  useEffect(() => {
    if (type === "request") {
      getServiceList();
    } else {
      getReuest();
    }
  }, []);

  return (
    <>
      <Box style={{ marginTop: "8px" }}>
        <Grid
          md={12}
          style={{
            marginTop: "0px",
            msOverflowStyle: "none",
            scrollbarWidth: "0px",
          }}
        >
          <Card
            title={
              type === "request"
                ? "Website Request"
                : type === "lead_reuest"
                ? "Lead Request"
                : "Pricing Request"
            }
            style={{
              marginTop: "30px",
              msOverflowStyle: "none",
            }}
          >
            <Table data={data} col={col} />
          </Card>
        </Grid>
      </Box>
      <ToastContainer />
    </>
  );
}
