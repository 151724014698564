import React, { useState, useEffect } from "react";
import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CNavTitle,
  CNavItem,
  CBadge,
  CNavGroup,
  CSidebarToggler,
} from "@coreui/react";
import {
  MdSpaceDashboard,
  MdLeaderboard,
  MdOutlineAdminPanelSettings,
  MdSupervisedUserCircle,
  MdHomeRepairService,
  MdOutlineFavoriteBorder,
} from "react-icons/md";
import { FaCalendarAlt } from "react-icons/fa";
import { CgCalendarToday } from "react-icons/cg";
import { AiOutlinePullRequest } from "react-icons/ai";
import { GrStatusGood } from "react-icons/gr";
import { FaIndustry } from "react-icons/fa";
import { TbStatusChange } from "react-icons/tb";
import { MdReport } from "react-icons/md";
import { TbFaceIdError } from "react-icons/tb";

import { Box } from "@mui/material";
import "./Sidebar.css";

export default function Sidebar(props) {
  const { to } = props;

  const [menu, setMenu] = useState([]);
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [type, setType] = useState("");

  const getSession = () => {
    let uid = localStorage.getItem("psAdminSessionID");
    let uname = localStorage.getItem("psAdminSessionName");
    let type = localStorage.getItem("psAdminSessionType");

    if (uid === null) {
      window.location.href = "/";
    } else {
      setName(uname);
      setType(type);
    }
  };
  useEffect(() => {
    getSession();
  });
  // console.log("/dashboard" === to);

  const menuUpdate = () => {
    if (type === "Jesta") {
      setMenu([
        {
          id: 1,
          menu: "Jesta Leads",
          link: "/dashboard",

          icon: (
            <MdSpaceDashboard
              customClassName="nav-icon"
              style={{ marginRight: "10px" }}
              color={"#ffffff"}
            />
          ),
        },
        {
          id: 2,
          menu: "Jesta Status",
          link: "/jesta_status",

          icon: (
            <MdSpaceDashboard
              customClassName="nav-icon"
              style={{ marginRight: "10px" }}
              color={"#ffffff"}
            />
          ),
        },
        {
          id: 3,
          menu: "Jesta Users",
          link: "/jesta_users",

          icon: (
            <MdSpaceDashboard
              customClassName="nav-icon"
              style={{ marginRight: "10px" }}
              color={"#ffffff"}
            />
          ),
        },
      ]);
    } else if (type === "Super Admin") {
      setMenu([
        {
          id: 1,
          menu: "Dashboard",
          link: "/dashboard",

          icon: (
            <MdSpaceDashboard
              customClassName="nav-icon"
              style={{ marginRight: "10px" }}
              color={"#ffffff"}
            />
          ),
        },
        {
          id: 13,
          menu: "Today's Service",
          link: "/currentService",
          icon: (
            <CgCalendarToday
              customClassName="nav-icon"
              style={{ marginRight: "10px" }}
              color={"#ffffff"}
            />
          ),
        },
        {
          id: 29,
          menu: "Today's Dispute",
          link: "/currentDispute",
          icon: (
            <TbFaceIdError
              customClassName="nav-icon"
              style={{ marginRight: "10px" }}
              color={"#ffffff"}
            />
          ),
        },
        {
          id: 130,
          menu: "Service Report",
          link: "/service_report",
          icon: (
            <MdReport
              customClassName="nav-icon"
              style={{ marginRight: "10px" }}
              color={"#ffffff"}
            />
          ),
        },
        {
          id: 14,
          menu: "Task",
          link: "/task",
          icon: (
            <CgCalendarToday
              customClassName="nav-icon"
              style={{ marginRight: "10px" }}
              color={"#ffffff"}
            />
          ),
        },
        {
          id: 2,
          menu: "Leads",
          link: "/leads",
          icon: (
            <MdLeaderboard
              customClassName="nav-icon"
              style={{ marginRight: "10px" }}
              color={"#ffffff"}
            />
          ),
        },
        {
          id: 3,
          menu: "Schedule",
          link: "/orders",
          icon: (
            <FaCalendarAlt
              customClassName="nav-icon"
              style={{ marginRight: "10px" }}
              color={"#ffffff"}
            />
          ),
        },
        {
          id: 4,
          menu: "Admin",
          link: "/admin",
          icon: (
            <MdOutlineAdminPanelSettings
              customClassName="nav-icon"
              style={{ marginRight: "10px" }}
              color={"#ffffff"}
            />
          ),
        },
        {
          id: 5,
          menu: "Employee",
          link: "/employee",
          icon: (
            <MdSupervisedUserCircle
              customClassName="nav-icon"
              style={{ marginRight: "10px" }}
              color={"#ffffff"}
            />
          ),
        },
        // {
        //   id: 6,
        //   menu: "Managers",
        //   link: "/manager",
        //   icon: (
        //     <MdSupervisedUserCircle
        //       customClassName="nav-icon"
        //       style={{ marginRight: "10px" }}
        //       color={"#ffffff"}
        //     />
        //   ),
        // },
        {
          id: 7,
          menu: "Website Service",
          link: "/website_service",
          icon: (
            <MdHomeRepairService
              customClassName="nav-icon"
              style={{ marginRight: "10px" }}
              color={"#ffffff"}
            />
          ),
        },
        {
          id: 8,
          menu: "Website Request",
          link: "/website_request/request",
          icon: (
            <AiOutlinePullRequest
              customClassName="nav-icon"
              style={{ marginRight: "10px" }}
              color={"#ffffff"}
            />
          ),
        },
        // {
        //   id: 9,
        //   menu: "Employee Attandance",
        //   link: "/attandance",
        //   icon: (
        //     <AiOutlinePullRequest
        //       customClassName="nav-icon"
        //       style={{ marginRight: "10px" }}
        //       color={"#ffffff"}
        //     />
        //   ),
        // },
        {
          id: 10,
          menu: "Leave Application",
          link: "/leave_application",
          icon: (
            <AiOutlinePullRequest
              customClassName="nav-icon"
              style={{ marginRight: "10px" }}
              color={"#ffffff"}
            />
          ),
        },
        {
          id: 11,
          menu: "Designation",
          link: "/designation",
          icon: (
            <AiOutlinePullRequest
              customClassName="nav-icon"
              style={{ marginRight: "10px" }}
              color={"#ffffff"}
            />
          ),
        },
        {
          id: 12,
          menu: "Lead Status",
          link: "/lead_status",
          icon: (
            <TbStatusChange
              customClassName="nav-icon"
              style={{ marginRight: "10px", color: "#FFF" }}
              color={"#ffffff"}
            />
          ),
        },
        {
          id: 13,
          menu: "Industry Type",
          link: "/industry_type",
          icon: (
            <FaIndustry
              customClassName="nav-icon"
              style={{ marginRight: "10px", color: "#FFF" }}
              color={"#ffffff"}
            />
          ),
        },
        {
          id: 14,
          menu: "Lead Request",
          link: "/website_request/lead_reuest",
          icon: (
            <AiOutlinePullRequest
              customClassName="nav-icon"
              style={{ marginRight: "10px" }}
              color={"#ffffff"}
            />
          ),
        },
        {
          id: 15,
          menu: "Pricing Request",
          link: "/website_request/pricing_reuest",
          icon: (
            <AiOutlinePullRequest
              customClassName="nav-icon"
              style={{ marginRight: "10px" }}
              color={"#ffffff"}
            />
          ),
        },
        {
          id: 16,
          menu: "Task Header",
          link: "/task_header",
          icon: (
            <AiOutlinePullRequest
              customClassName="nav-icon"
              style={{ marginRight: "10px" }}
              color={"#ffffff"}
            />
          ),
        },
        {
          id: 17,
          menu: "Our Documents",
          link: "/company_certificates",
          icon: (
            <AiOutlinePullRequest
              customClassName="nav-icon"
              style={{ marginRight: "10px" }}
              color={"#ffffff"}
            />
          ),
        },
        {
          id: 18,
          menu: "Review/Report Header",
          link: "/report_header",
          icon: (
            <AiOutlinePullRequest
              customClassName="nav-icon"
              style={{ marginRight: "10px" }}
              color={"#ffffff"}
            />
          ),
        },
      ]);
    } else if (type === "Jesta User") {
      setMenu([
        {
          id: 1,
          menu: "Jesta Leads",
          link: "/dashboard",

          icon: (
            <MdSpaceDashboard
              customClassName="nav-icon"
              style={{ marginRight: "10px" }}
              color={"#ffffff"}
            />
          ),
        },
        {
          id: 2,
          menu: "Jesta Status",
          link: "/jesta_status",

          icon: (
            <MdSpaceDashboard
              customClassName="nav-icon"
              style={{ marginRight: "10px" }}
              color={"#ffffff"}
            />
          ),
        },
      ]);
    }
  };
  //14
  useEffect(() => {
    menuUpdate();
  });

  return (
    <div
      style={{
        padding: "0px",
        margin: "0px",
        height: "100vh",
        background: "#3e4095",
      }}
    >
      <Box md={{ width: "100%" }}>
        <CSidebar
          style={{
            width: "100%",

            padding: "0px",
          }}
          colorScheme="dark"
        >
          <CSidebarBrand>Hello, {name}</CSidebarBrand>
          {menu.map((item) => {
            return (
              <CSidebarNav key={item.id} style={{}}>
                <CNavItem
                  href={item.link}
                  active={item.link === to ? true : false}
                  style={{ color: "#FFFFFF" }}
                >
                  {item.icon} {item.menu}
                </CNavItem>
              </CSidebarNav>
            );
          })}
        </CSidebar>
      </Box>
    </div>
  );
}
