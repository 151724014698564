import React, { useState, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import { Card, Space, Button, Tag, Modal, Form, Input } from "antd";
import { ToastContainer, toast } from "react-toastify";
import Zoom from "react-medium-image-zoom";
import Table from "../../Component/Table/Table";
import { psApiCalling, uploadImage } from "../../Component/API/Index";
import { Image } from "react-bootstrap";
import { SyncLoader } from "react-spinners";
import { FileUploader } from "react-drag-drop-files";
export default function LeadsCounter() {
  const fileTypes = ["jpg", "png", "gif", "jpeg", "pdf"];
  const [employee_id, setEmployeeId] = useState(
    window.location.pathname.split("/")[2]
  );
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [selectedid, setselectedid] = useState(null);
  const [selectedPassword, setSelectedPassword] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [website, setWebsite] = useState(null);
  function getLastThreeWords(str) {
    // Split the string by spaces to get an array of words
    const wordsArray = str.trim().split(" ");

    // Get the last three words from the array
    const lastThreeWords = wordsArray.slice(-3);

    // Join the words back into a string
    //console.log("here", lastThreeWords.join(" "));
    return str.substr(str.length - 3);
    //return lastThreeWords.join(" ");
  }
  const [col, setCol] = useState([
    {
      title: "Name",
      key: "name",
      render: (item, record) => <Tag color={"#27ae60"}>{item.name}</Tag>,
    },
    {
      title: "Document",
      key: "lead_status",
      render: (item, record) => (
        <>
          {console.log("url", getLastThreeWords(item.image))}
          {getLastThreeWords(item.image) === "pdf" ? (
            <a href={item.image} target={"_blank"} rel={"no"}>
              <Image
                src={
                  "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/800px-PDF_file_icon.svg.png"
                }
                alt={"Service Image"}
                fluid
                style={{ width: "100px", height: "100px" }}
              />
            </a>
          ) : (
            <Zoom>
              <Image
                src={item.image}
                alt={"Service Image"}
                fluid
                style={{ width: "100px", height: "100px" }}
              />
            </Zoom>
          )}
        </>
      ),
    },

    {
      title: "Action",
      key: "action",
      fixed: "right",
      render: (item, record) => (
        <>
          <Button
            danger
            onClick={() => {
              deleteCert(item.id);
            }}
          >
            Delete
          </Button>
        </>
      ),
    },
  ]);
  const uploadImageToServer = (file) => {
    setIsLoading(true);

    let formdata = new FormData();
    formdata.append("image", file);

    uploadImage(formdata).then((res) => {
      //console.log(res);
      setIsLoading(false);
      if (res.data.status === "success") {
        setWebsite(res.data.imageURL);
      }
    });
  };

  const deleteCert = (id) => {
    let params = { action: "DELETE_COMPANY_CERTIFICATE", id: id };
    psApiCalling(params).then((res) => {
      if (res.status === "success") {
        toast.success(res.message);
        getLeadsList();
      } else {
        toast.error(res.message);
      }
    });
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onFinishPassword = (values) => {
    if (website === null) {
      toast.error("Please select certificate document");
      return;
    }
    let params = {
      action: "CREATE_COMPANY_CERTIFICATE",

      image: website,
      name: values.name,
    };
    psApiCalling(params).then((res) => {
      if (res.status === "success") {
        setIsModalOpen(false);
        toast.success(res.message);
        getLeadsList();
      } else {
        toast.error(res.message);
      }
    });
  };
  const getLeadsList = () => {
    let params = { action: "GET_COMPANY_CERTIFICATES" };
    psApiCalling(params).then((res) => {
      if (Array.isArray(res)) {
        let arr = res.filter((item) => item.cid === employee_id);
        setData(res);
      }
    });
  };
  useEffect(() => {
    getLeadsList();
  }, []);

  return (
    <Box>
      <Box md={{ width: "100%" }} style={{ marginTop: "8px" }}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 1, md: 1 }}
          sx={{ flexDirection: { xs: "column", md: "row" } }}
        >
          <Grid
            md={12}
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 1, md: 1 }}
            sx={{ flexDirection: { xs: "column", md: "row" } }}
            style={{ marginTop: "0px" }}
          >
            <Card
              title="Company Document"
              extra={
                <Button
                  onClick={() => setIsModalOpen(true)}
                  style={{ background: "#5f27cd", color: "#FFFFFF" }}
                >
                  Add Document
                </Button>
              }
              style={{
                width: "98%",
                marginLeft: "1%",
                marginTop: "30px",
              }}
            >
              <Table data={data} col={col} />
            </Card>
          </Grid>
        </Grid>
        <Modal
          title="Add New Certificate"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form
            form={form}
            name="complex-form"
            layout="vertical"
            onFinish={onFinishPassword}
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 32,
            }}
          >
            <Form.Item
              label="Document Name"
              name="name"
              style={{
                display: "inline-block",
                width: "calc(100% - 0px)",
              }}
              rules={[
                {
                  required: true,
                  message: "Please write document name",
                },
              ]}
            >
              <Input placeholder="Document Name" />
            </Form.Item>
            <Form.Item
              label="Document"
              name="certificate_image"
              style={{
                display: "inline-block",
                width: "calc(100% - 0px)",
              }}
            >
              <FileUploader
                handleChange={(file) => {
                  uploadImageToServer(file);
                }}
                name="file"
                types={fileTypes}
                multiple={false}
                style={{ width: "100%" }}
              />
            </Form.Item>
            {isLoading ? (
              <SyncLoader
                color={"#008080"}
                size={15}
                aria-label="Loading Spinner"
                data-testid="loader"
                cssOverride={{ marginTop: "20px" }}
                style={{ marginTop: "20px" }}
                margin={"20px"}
              />
            ) : (
              <Form.Item label=" " colon={false}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%", background: "#2ecc71" }}
                >
                  Add Document
                </Button>
              </Form.Item>
            )}
          </Form>
        </Modal>
      </Box>
      <ToastContainer />
    </Box>
  );
}
