import React, { useState, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import { Card, Space, Button, Tag } from "antd";
import { ToastContainer, toast } from "react-toastify";
import Table from "../../Component/Table/Table";
import { psApiCalling } from "../../Component/API/Index";
import moment from "moment";

export default function ServiceReportList(props) {
  const [data, setData] = useState([]);
  const [col, setCol] = useState([
    // {
    //   title: "Service Date",

    //   key: "service_date",
    //   render: (item, record) => (
    //     <Tag color="#0be881">
    //       {moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY")}
    //     </Tag>
    //   ),
    // },
    {
      title: "Service Date",
      render: (item, record) => <Tag color="#3c40c6">{item.sdate}</Tag>,
      key: "service_guy",
    },
    {
      title: "Client Name",
      render: (item, record) => <Tag color="#1abc9c">{item.cname}</Tag>,
      key: "service_guy",
    },
    {
      title: "Service Technician",
      render: (item, record) => <Tag color="#00d8d6">{item.tname}</Tag>,
      key: "service_day",
    },
    // {
    //   title: "Service Type",
    //   render: (item, record) => <Tag color="#3498db">{item.cname}</Tag>,
    //   key: "service_day",
    // },
    {
      title: "Subject",
      render: (item, record) => <Tag color="#e74c3c">{item.header_name}</Tag>,
      key: "service_day",
    },
    {
      title: "Message",
      render: (item, record) => <Tag color="#e67e22">{item.message}</Tag>,
      key: "service_day",
    },
    {
      title: "Report Time",
      render: (item, record) => <Tag color="#8e44ad">{item.time}</Tag>,
      key: "service_day",
    },

    {
      title: "View Details",
      key: "action",
      render: (item, record) => (
        <>
          {item.status === "Pending" ? (
            // <Button
            //   type="primary"
            //   href={
            //     "/manageSchedule/" +
            //     item.id +
            //     "/" +
            //     item.oid +
            //     "/" +
            //     moment(item.date, "YYYY-MM-DD").format("YYYY-MM-DD")
            //   }
            // >
            //   Manage Schedule
            // </Button>
            <></>
          ) : (
            <Button
              type="primary"
              href={"/viewService/" + item.sid + "/" + item.oid}
              style={{ background: "#0be881" }}
            >
              View Service
            </Button>
          )}
        </>
      ),
    },
  ]);

  const getLeadList = () => {
    let params = {
      action: "GET_ALL_SERVICE_REPORT_LIST",
    };
    psApiCalling(params).then((res) => {
      if (Array.isArray(res)) {
        setData(res);
      }
    });
  };
  const getDatName = (date) => {
    const dateObject = moment(date);
    const dayName = dateObject.format("dddd");
    return dayName;
  };

  useEffect(() => {
    getLeadList();
  }, []);

  const today = () => {
    let today = moment().toString();
    let format = moment(today).format("DD-MM-YYYY");
    return format;
  };

  return (
    <>
      <Box style={{ marginTop: "8px" }}>
        <Grid
          md={12}
          style={{
            marginTop: "0px",
            msOverflowStyle: "none",
            scrollbarWidth: "0px",
          }}
        >
          <Card
            title={"Service Report"}
            style={{
              marginTop: "30px",
              msOverflowStyle: "none",
            }}
          >
            <Table data={data} col={col} />
          </Card>
        </Grid>
      </Box>
      <ToastContainer />
    </>
  );
}
