import React, { useState, useEffect, useRef } from "react";
import { Grid, Box } from "@mui/material";
import {
  Card,
  Input,
  Button,
  Modal,
  Divider,
  Form,
  Spin,
  Select,
  Tag,
} from "antd";
import { ToastContainer, toast } from "react-toastify";
import Table from "../../Component/Table/Table";
import { LoadingOutlined } from "@ant-design/icons";
import { psApiCalling } from "../../Component/API/Index";

export default function DesignationList() {
  const [updateForm] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [update, setUpdate] = useState(false);
  const [updatedId, setUpdatedId] = useState(null);
  const [currentAdd, setCurrentAdd] = useState("");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const [form_one] = Form.useForm();
  const [designtionList, setDesigntionList] = useState([]);
  const [updatedID, setUpdatedID] = useState(null);
  const [updatedName, setUpdatedName] = useState(null);
  const [updatedParent, setUpdatedParent] = useState(null);
  const [leadList, setLeadList] = useState([]);
  const [comment, setComment] = useState(null);
  const [unique_id, setunique_id] = useState(null);

  const [col, setCol] = useState([
    {
      title: "Query Time",
      key: "parent",
      width: "10%",
      render: (item) => <Tag color={"#12CBC4"}>{item.QUERY_TIME}</Tag>,
    },
    {
      title: "Sender Name",
      key: "parent",
      render: (item) => <Tag color={"#FDA7DF"}>{item.SENDER_NAME}</Tag>,
    },
    {
      title: "Mobile",
      key: "parent",
      render: (item) => <Tag color={"#F79F1F"}>{item.SENDER_MOBILE}</Tag>,
    },
    {
      title: "Email",
      key: "parent",
      render: (item) => <Tag color={"#009432"}>{item.SENDER_EMAIL}</Tag>,
    },
    {
      title: "Product",
      key: "parent",
      render: (item) => <Tag color={"#1e3799"}>{item.QUERY_PRODUCT_NAME}</Tag>,
    },
    {
      title: "Subject",
      key: "parent",
      render: (item) => <Tag color={"#0652DD"}>{item.SUBJECT}</Tag>,
    },

    {
      title: "Message",
      key: "parent",
      render: (item) => <Tag color={"#0c2461"}>{item.QUERY_MESSAGE}</Tag>,
    },
    {
      title: "MCAT",
      key: "parent",
      render: (item) => <Tag color={"#b71540"}>{item.QUERY_MCAT_NAME}</Tag>,
    },
    {
      title: "Company",
      key: "parent",
      render: (item) => <Tag color={"#9980FA"}>{item.SENDER_COMPANY}</Tag>,
    },
    {
      title: "Address",
      key: "parent",
      render: (item) => <Tag color={"#833471"}>{item.SENDER_ADDRESS}</Tag>,
    },

    {
      title: "City/State/Pincode",
      key: "parent",
      render: (item) => (
        <Tag color={"#006266"}>
          {item.SENDER_CITY}/{item.SENDER_STATE}/{item.SENDER_PINCODE}/
          {item.SENDER_COUNTRY_ISO}
        </Tag>
      ),
    },
    {
      title: "Call Duration",
      key: "parent",
      render: (item) => <Tag color={"#e55039"}>{item.CALL_DURATION}</Tag>,
    },
    {
      title: "Receiver Mobile",
      key: "parent",
      render: (item) => <Tag color={"#e58e26"}>{item.RECEIVER_MOBILE}</Tag>,
    },
    // {
    //   title: "ID",
    //   key: "id",
    //   render: (item) => <Tag color={"#b8e994"}>{item.id}</Tag>,
    // },
    {
      title: "Query Id",
      key: "designation",

      render: (item) => <Tag color={"#FFC312"}>{item.UNIQUE_QUERY_ID}</Tag>,
    },

    {
      title: "Query Type",
      key: "parent",
      render: (item) => <Tag color={"#C4E538"}>{item.QUERY_TYPE}</Tag>,
    },
    {
      title: "Alt Mobile",
      key: "parent",
      render: (item) => <Tag color={"#1B1464"}>{item.SENDER_MOBILE_ALT}</Tag>,
    },
    {
      title: "Phone",
      key: "parent",
      render: (item) => <Tag color={"#5758BB"}>{item.SENDER_PHONE}</Tag>,
    },
    {
      title: " Alt Phone",
      key: "parent",
      render: (item) => <Tag color={"#6F1E51"}>{item.SENDER_PHONE_ALT}</Tag>,
    },
    {
      title: "Email Alt",
      key: "parent",
      render: (item) => <Tag color={"#60a3bc"}>{item.SENDER_EMAIL_ALT}</Tag>,
    },
    {
      title: "Status",
      key: "parent",
      render: (item) => (
        <>
          <Select
            options={JSON.parse(item.list)}
            defaultValue={item.status}
            onChange={(value) => updateStatus(value, item.UNIQUE_QUERY_ID)}
            style={{ width: "120px" }}
          />
        </>
      ),
    },

    {
      title: "Last Update Time",
      key: "parent",
      render: (item) => <Tag color={"#60a3bc"}>{item.update_time}</Tag>,
    },
    {
      title: "Comment",
      key: "parent",
      render: (item) => (
        <>
          {Array.isArray(JSON.parse(item.comments)) ? (
            <>
              {JSON.parse(item.comments).map((item) => {
                return (
                  <>
                    <p>{item.comment} </p>
                    <p style={{ fontSize: "12px" }}>{item.time}</p>
                  </>
                );
              })}
            </>
          ) : null}
          {/* <Input
            style={{ width: "200px" }}
            onChange={(e) => {
              setComment(e.target.value);
              console.log(e.target.value);
            }}
          /> */}
          <Button
            onClick={() => {
              // updateComment(item.UNIQUE_QUERY_ID);
              setunique_id(item.UNIQUE_QUERY_ID);
              setOpen(true);
            }}
          >
            Add Comment
          </Button>
        </>
      ),
    },
    {
      title: "Assigned To",
      key: "parent",
      render: (item) => (
        <>
          <Select
            options={JSON.parse(item.users)}
            defaultValue={item.assigned}
            onChange={(value) => updateAssign(value, item.UNIQUE_QUERY_ID)}
            style={{ width: "120px" }}
          />
        </>
      ),
    },
  ]);

  const updateAssign = (value, id) => {
    let params = { action: "ASSIGN_JESTA_LEADS", value: value, uid: id };
    psApiCalling(params).then((res) => {
      if (res.status === "success") {
        toast.success(res.message);
      } else {
        toast.success(res.message);
      }
    });
  };

  const getAllDesignation = () => {
    let type = localStorage.getItem("psAdminSessionType");
    let uid = localStorage.getItem("psAdminSessionID");
    if (type === "Jesta User") {
      let params = { action: "GET_JESTA_LEADS_USER", uid: uid };
      psApiCalling(params).then((res) => {
        if (Array.isArray(res)) {
          setData(res);
        }
      });
    } else {
      let params = { action: "GET_JESTA_LEADS" };
      psApiCalling(params).then((res) => {
        if (Array.isArray(res)) {
          setData(res);
        }
      });
    }
  };
  const onFinish = (value) => {
    let id = localStorage.getItem("psAdminSessionID");
    let params = {
      action: "UPDATE_JESTA_LEADS_COMMENT",
      value: value.comment,
      uid: unique_id,
      id: id,
    };
    psApiCalling(params).then((res) => {
      //  setComment(null);
      if (res.status === "success") {
        toast.success("Comment Saved");
        setOpen(false);
      } else {
        toast.error(res.message);
      }
    });
  };
  // const getLeadList = () => {
  //   let params = { action: "GET_ACTIVE_JESTA_LEAD_STATUS" };
  //   psApiCalling(params).then((res) => {
  //     if (Array.isArray(res)) {
  //       setLeadList(
  //         res.map((item) => {
  //           return {
  //             value: item.status,
  //             label: item.status,
  //           };
  //         })
  //       );
  //       getAllDesignation();
  //     }
  //   });
  // };

  useEffect(() => {
    getAllDesignation();
  }, []);

  const updateStatus = (v, uid) => {
    console.log(uid, v);
    let params = { action: "UPDATE_JESTA_LEADS", value: v, uid: uid };
    psApiCalling(params).then((res) => {
      if (res.status === "success") {
        toast.success(res.message);
      } else {
        toast.success(res.message);
      }
    });
  };
  const updateComment = (uid) => {
    console.log(comment);
    let id = localStorage.getItem("psAdminSessionID");
    let params = {
      action: "UPDATE_JESTA_LEADS_COMMENT",
      value: comment,
      uid: uid,
      id: id,
    };
    psApiCalling(params).then((res) => {
      //  setComment(null);
      if (res.status === "success") {
        toast.success("Comment Saved");
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <>
      <Box style={{ marginTop: "8px" }}>
        <Grid
          md={12}
          style={{
            marginTop: "0px",

            scrollbarWidth: "0px",
          }}
        >
          <Card
            title="Jesta Leads"
            style={{
              marginTop: "30px",
              overflowX: "scroll",
            }}
          >
            <Table data={data} col={col} />
          </Card>
        </Grid>
      </Box>
      <Modal
        title="Add New Comment"
        centered
        open={open}
        width={600}
        footer={null}
        onCancel={() => setOpen(false)}
      >
        <Divider />
        <div>
          <Form
            form={form_one}
            layout="vertical"
            style={{
              marginBottom: 0,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              label="Comment"
              name="comment"
              rules={[
                {
                  required: true,
                  message: "Comment is required",
                },
              ]}
              style={{
                display: "inline-block",
                width: "calc(99.8% - 8px)",
              }}
            >
              <Input placeholder="Comment..." />
            </Form.Item>

            <Form.Item label=" " colon={false}>
              {isLoading ? (
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 24,
                      }}
                      spin
                    />
                  }
                />
              ) : (
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%", background: "#3E4095" }}
                >
                  Add Comment
                </Button>
              )}
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <ToastContainer />
    </>
  );
}
