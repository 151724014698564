import React, { useState, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import { Card, Space, Button, Tag, Modal, Form, Input } from "antd";
import { ToastContainer, toast } from "react-toastify";
import { psApiCalling } from "../API/Index";
import Table from "../../Component/Table/Table";
export default function LeadsCounter() {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [selectedid, setselectedid] = useState(null);
  const [selectedPassword, setSelectedPassword] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [col, setCol] = useState([
    // {
    //   title: "ID",
    //   key: "id",
    //   render: (item, record) => <>{item.data.id}</>,
    // },
    // {
    //   title: "Lead ID",
    //   key: "lead_id",
    //   render: (item, record) => <Tag color="green">{item.data.client_id}</Tag>,
    // },
    {
      title: "Client Name",
      key: "client_name",
      render: (item, record) => (
        <Tag color="purple">{item.data.client_name}</Tag>
      ),
    },
    {
      title: "Lead Status",
      key: "lead_status",
      render: (item, record) => <Tag color="yellow">{item.data.status}</Tag>,
    },
    {
      title: "Client Phone",
      key: "client_phone",
      render: (item, record) => (
        <Tag color="#01a3a4">{item.data.client_phone}</Tag>
      ),
    },
    {
      title: "Client Email",
      key: "client_email",
      width: "180px",
      render: (item, record) => (
        <Tag color="#feca57">{item.data.client_email}</Tag>
      ),
    },
    {
      title: "Add Sites",

      render: (item, record) => (
        <Button
          href={"/manage_sites/" + item.data.client_id + "/" + item.data.id}
          type="primary"
          style={{ backgroundColor: "#5f27cd" }}
        >
          Sites
        </Button>
      ),
    },
    {
      title: "Contact Persons",
      width: "180px",
      render: (item, record) => (
        <Button
          href={
            "/manage_contact_person/" + item.data.client_id + "/" + item.data.id
          }
          type="primary"
          style={{ backgroundColor: "#ff9f43" }}
        >
          Contact Person
        </Button>
      ),
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      render: (item, record) => (
        <>
          <Button
            href={"/edit_lead/" + item.data.client_id + "/" + item.data.id}
            type="primary"
            style={{ backgroundColor: "#f6b93b" }}
          >
            Edit Lead
          </Button>
          <Button
            onClick={() => {
              setIsModalOpen(true);
              setselectedid(item.data.id);
              setSelectedPassword(item.data.client_password);

              form.setFieldsValue({
                employee_password: item.data.client_password,
              });
            }}
            type="primary"
            style={{ backgroundColor: "#e67e22" }}
          >
            Password
          </Button>
          <Button
            href={"/lead_certificates/" + item.data.id}
            type="primary"
            style={{ backgroundColor: "#2ecc71" }}
          >
            Documents
          </Button>
        </>
      ),
    },
  ]);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onFinishPassword = (values) => {
    let params = {
      action: "UPDATE_LEAD_PASSWORD",
      id: selectedid,
      password: values.employee_password,
    };
    psApiCalling(params).then((res) => {
      if (res.status === "success") {
        toast.success(res.message);
        getLeadsList();
      } else {
        toast.error(res.message);
      }
    });
  };
  const getLeadsList = () => {
    let params = { action: "GET_ALL_LEADS" };
    psApiCalling(params).then((res) => {
      if (Array.isArray(res)) {
        setData(res);
      }
    });
  };
  useEffect(() => {
    getLeadsList();
  }, []);

  return (
    <Box>
      <Box md={{ width: "100%" }} style={{ marginTop: "8px" }}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 1, md: 1 }}
          sx={{ flexDirection: { xs: "column", md: "row" } }}
        >
          <Grid
            md={12}
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 1, md: 1 }}
            sx={{ flexDirection: { xs: "column", md: "row" } }}
            style={{ marginTop: "0px" }}
          >
            <Card
              title="Leads"
              extra={
                <Button
                  href="/createLead"
                  style={{ background: "#5f27cd", color: "#FFFFFF" }}
                >
                  Create New Lead
                </Button>
              }
              style={{
                width: "98%",
                marginLeft: "1%",
                marginTop: "30px",
              }}
            >
              <Table data={data} col={col} />
            </Card>
          </Grid>
        </Grid>
        <Modal
          title="Password"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form
            form={form}
            name="complex-form"
            layout="vertical"
            onFinish={onFinishPassword}
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
          >
            <Form.Item
              style={{
                marginBottom: 0,
              }}
            >
              <Form.Item
                label="Lead Password"
                name="employee_password"
                rules={[
                  {
                    required: true,
                    message: "Lead password is required",
                  },
                ]}
                style={{
                  display: "inline-block",
                  width: "calc(100% - 0px)",
                }}
              >
                <Input.Password
                  placeholder="Lead Password"
                  value={selectedPassword}
                />
              </Form.Item>
            </Form.Item>
            <Form.Item label=" " colon={false}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%", background: "#f1c40f" }}
              >
                Update Password
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </Box>
      <ToastContainer />
    </Box>
  );
}
